import { createRouter, createWebHistory } from '@ionic/vue-router';
import Profile from '../views/Profile.vue'
import MyClub from '../views/MyClub.vue'
import store from '../main.js'
import Tournaments from '../views/Tournaments.vue'
import Events from '../views/Events.vue'
import Teetime from '../views/Teetime.vue'
import Friends from '../views/Friends.vue'
import Card from '../views/Card.vue'
import Pros from '../views/Pros.vue'
import Pro from '../views/Pro.vue'
import Courses from '../views/Courses.vue'
import Ranges from '../views/Ranges.vue'
import Range from '../views/Range.vue'
import Wellnesses from '../views/Wellnesses.vue'
import Wellness from '../views/Wellness.vue'
import Trackmans from '../views/Trackmans.vue'
import Trackman from '../views/Trackman.vue'
import Indoors from '../views/Indoors.vue'
import Indoor from '../views/Indoor.vue'
import Home from '../views/Home.vue'
import Teetimes from '../views/Teetimes.vue'
import Livescorings from '../views/Livescorings.vue'
import Livescoring from '../views/Livescoring.vue'
import LivescoringOnly from '../views/LivescoringOnly.vue'
import LivescoringRPR from '../views/LivescoringRPR.vue'
import LivescoringSC from '../views/LivescoringSC.vue'
import News from '../views/News.vue'
import NewsPerson from '../views/NewsPerson.vue'
import Verify from '../views/Verify.vue'
import Reset from '../views/Reset.vue'
import Infoscreen from '../views/Infoscreen.vue'
import PaceOfPlay from '../views/PaceOfPlay.vue'
import Teetimestatus from '../views/Teetimestatus.vue'
import Marshall from '../views/Marshall.vue'
import Marshalls from '../views/Marshalls.vue'
import Faq from '../views/Faq.vue'
import NotFound from '../views/NotFound.vue'
import Tutorial from '../views/Tutorial.vue'
import devTools from "devtools-detect"
import Startlistscreen from '../views/Startlistscreen.vue'
import NextCompetitionsscreen from '../views/NextCompetitionsscreen.vue'
import { isPlatform } from '@ionic/vue';
import TournamentOverview from '../views/TournamentOverview.vue'
import TournamentStartList from '../views/TournamentStartList.vue'
import TournamentLiveScoringHH from '../views/TournamentLiveScoringHH.vue'
import TournamentResultList from '../views/TournamentResultList.vue'
import ScoreWeb from '../views/ScoreWeb.vue'
import GuestsQRCode from '../views/GuestsQRCode.vue'
import Voucher from '../views/Voucher.vue'
import PCA from '../views/PCA.vue'

const routes = [
  {
    path: '/404',
    name: '404',
    component: NotFound
  },
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/teetimes',
    name: 'teetimes',
    component: Teetimes
  },
  {
    path: '/teetime/:id',
    name: 'teetime_id',
    component: Teetime
  },
  {
    path: '/teetime/:id/:date',
    name: 'teetime_date',
    component: Teetime
  },
  {
    path: '/pros',
    name: 'pros',
    component: Pros
  },
  {
    path: '/pro/:id/:date',
    name: 'pro_date',
    component: Pro
  },
  {
    path: '/courses/:date',
    name: 'courses_',
    component: Courses
  },
  {
    path: '/courses',
    name: 'courses',
    component: Courses
  },
  {
    path: '/ranges',
    name: 'ranges',
    component: Ranges
  },
  {
    path: '/range/:id/:date',
    name: 'range_date',
    component: Range
  },
  {
    path: '/wellnesses',
    name: 'wellnesses',
    component: Wellnesses
  },
  {
    path: '/wellnesse/:id/:date',
    name: 'wellnesse_date',
    component: Wellness
  },
  {
    path: '/trackmans',
    name: 'trackmans',
    component: Trackmans
  },
  {
    path: '/trackman/:id/:date',
    name: 'trackman_date',
    component: Trackman
  },
  {
    path: '/indoors',
    name: 'indoors',
    component: Indoors
  },
  {
    path: '/indoor/:id/:date',
    name: 'indoor_date',
    component: Indoor
  },
  {
    path: '/competitions/:date',
    name: 'Competitions_',
    component: Tournaments
  },
  {
    path: '/competitions',
    name: 'Competitions',
    component: Tournaments
  },
  {
    path: '/livescorings',
    name: 'Livescoring',
    component: Livescorings
  },
  {
    path: '/livescoring/:id',
    name: 'livescoring_',
    component: Livescoring
  },
  {
    path: '/livescoring/:tid/:rid/:rpid/:pid',
    name: 'livescoringnologin_',
    component: Livescoring
  },
  {
    path: '/livescoringonly/:id',
    name: 'livescoringonly_',
    component: LivescoringOnly
  },
  {
    path: '/livescoringsc/:id/:hcpi',
    name: 'livescoringsc_',
    component: LivescoringSC
  },
  {
    path: '/livescoringrpr/:id',
    name: 'livescoringrpr_',
    component: LivescoringRPR
  },
  {
    path: '/events/:date',
    name: 'Events_',
    component: Events
  },
  {
    path: '/events',
    name: 'Events',
    component: Events
  },
  {
    path: '/myclub',
    name: 'MyClub',
    component: MyClub
  },
  {
    path: '/friends',
    name: 'Friends',
    component: Friends
  },
  {
    path: '/card',
    name: 'Card',
    component: Card
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/newsperson',
    name: 'NewsPerson',
    component: NewsPerson
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/tutorial',
    name: 'Tutorial',
    component: Tutorial
  },
  {
    path: '/verify/:token',
    name: 'Verify',
    title: 'Verify',
    component: Verify
  },
  {
    path: '/reset/:token',
    name: 'Reset',
    title: 'Reset',
    component: Reset
  },
  {
    path: '/infoscreen/:id',
    name: 'Infoscreen',
    title: 'Infoscreen',
    showNav: false,
    component: Infoscreen
  },
  {
    path: '/paceofplay',
    name: 'PaceOfPlay',
    title: 'PaceOfPlay',
    showNav: false,
    component: PaceOfPlay
  },
  {
    path: '/startlistscreen',
    name: 'Startlistscreen',
    title: 'Startlistscreen',
    showNav: false,
    component: Startlistscreen
  },
  {
    path: '/nextcompetitionsscreen',
    name: 'NextCompetitionsscreen',
    title: 'NextCompetitionsscreen',
    showNav: false,
    component: NextCompetitionsscreen
  },
  {
    path: '/teetimestatus/:id',
    name: 'Teetimestatus',
    title: 'Teetimestatus',
    showNav: false,
    component: Teetimestatus
  },
  {
    path: '/marshalls',
    name: 'marshalls',
    component: Marshalls
  },
  {
    path: '/marshall/:id/:date',
    name: 'Marshall',
    title: 'Marshall',
    showNav: false,
    component: Marshall
  },
  {
    path: '/marshall/:id',
    name: 'Marshall_',
    title: 'Marshall',
    showNav: false,
    component: Marshall
  },
  {
    path: '/tournament',
    name: 'TournamentOverview',
    title: 'TournamentOverview',
    showNav: false,
    component: TournamentOverview
  },
  {
    path: '/tournament/startlist/:tid/:roundIndex',
    name: 'TournamentStartList',
    title: 'TournamentStartList',
    showNav: false,
    component: TournamentStartList
  },
  {
    path: '/tournament/livescoringhh/:roundid',
    name: 'TournamentLiveScoringHH',
    title: 'TournamentLiveScoringHH',
    showNav: false,
    component: TournamentLiveScoringHH
  },
  {
    path: '/tournament/livescoring/:roundid',
    name: 'TournamentLiveScoring',
    title: 'TournamentLiveScoring',
    showNav: false,
    component: TournamentLiveScoringHH
  },
  {
    path: '/tournament/resultlist/:roundid/:prizeid',
    name: 'TournamentResultList',
    component: TournamentResultList
  },
  {
    path: '/score/:tid',
    name: 'ScoreWeb',
    component: ScoreWeb
  },
  {
    path: '/scorecard_qrcodes',
    name: 'GuestsQRCode',
    component: GuestsQRCode
  },
  {
    path: '/voucher',
    name: 'Voucher',
    title: 'Voucher',
    component: Voucher
  },
  {
    path: '/pca',
    name: 'PCA',
    title: 'PCA',
    component: PCA
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) =>  {
  /*if (devTools.isOpen && to.name != '404' && !isPlatform('ios') && !isPlatform('android') && store.getters.httpLink != 'http://localhost:4000' && !window.location.search.includes('2ec9dfb18c685ab3c38375f3d80f3481.access')) {
    router.push('/404')
    return
  }
  else */{ 
    if(to.matched.some(record => record.meta.requiresAuth)) {
      if (store.getters.isLoggedIn) {
      next()
        return
      }
      next('/login')
    } else {
      next()
    }
  }
})

export default router
