module.exports = {
  "translation": {
    "clubinone": "CLUBINONE 4U",
    "home": "Mi Club",
    "pros": "Pros",
    "persons": "Personas",
    "competitions": "Torneos",
    "competition": "torneo",
    "competitionUC": "Torneo",
    "bookings": "Reservas",
    "statistics": "Estadísticas",
    "settings": "Ajustes",
    "profile": "Perfil",
    "login": "Inicio de sesión",
    "logout": "Cierre de sesión",
    "organizations-list": "Mis organizaciones",
    "companies": "Clubes",
    "company": "Club",
    "searchcompanies": "Buscar clubes",
    "searchresult": "Resultado de la búsqueda",
    "history": "Recientes",
    "register": "Registro",
    "save": "Guardar",
    "userData": "Datos del usuario",
    "changePassword": "Cambiar contraseña",
    "changeEmail": "Cambiar el correo electrónico",
    "language": "Idioma",
    "firstName": "Nombre",
    "lastName": "Apellido",
    "birthdate": "Cumpleaños",
    "password": "Contraseña",
    "email": "Envíe un correo electrónico a",
    "profilePicture": "Foto de perfil",
    "takePicture": "Seleccionar imagen",
    "upload": "Guardar imagen",
    "passwordReset": "Enviar correo electrónico de restablecimiento de contraseña",
    "events": "Eventos",
    "event": "evento",
    "eventUC": "Evento",
    "loading": "Cargando datos ...",
    "newcompanies": "Conviértase en anfitrión",
    "erroLogin": "Por favor, inicie sesión para crear un club.",
    "newCategory": "Nueva categoría",
    "description": "Descripción",
    "changeSuccessful": "Cambiar con éxito",
    "changeNotSuccessful": "Error durante el cambio",
    "deleteSuccessful": "Borrar con éxito",
    "deleteNotSuccessful": "Error durante el borrado",
    "addSuccessful": "Añadir con éxito",
    "addNotSuccessful": "Error durante la adición",
    "notSuccessful": "Se ha producido un error",
    "name": "Nombre",
    "shortName": "Nombre corto",
    "priority": "Prioridad",
    "delete": "Borrar",
    "cancel": "Cancelar",
    "ok": "OK",
    "bookingDuration": "Duración de la reserva",
    "showAvailableSlots": "Mostrar las franjas horarias disponibles",
    "showBookedSlots": "Mostrar las franjas horarias reservadas",
    "withSlider": "Mostrar el control deslizante",
    "intervallBetweenBookings": "Tiempo entre dos reservas",
    "maxBookingsInFuture": "Máximo de reservas",
    "maxBookingsPerDay": "Máximo de reservas por día",
    "maxBookingsPerWeek": "Máximo de reservas por semana",
    "maxPersonsBookable": "Número máximo de plazas reservables",
    "minPersonsBookable": "Número mínimo de plazas reservables",
    "newBookingType": "Nuevo tipo de reserva",
    "from": "Período de",
    "to": "Período a",
    "cart": "Carrito",
    "teetimes": "Teetimes",
    "pro": "Pro",
    "pros": "Pro",
    "courses": "Cursos",
    "course": "Curso",
    "ranges": "Gama",
    "available": "",
    "sperrungnoshow": "Bloqueado",
    "showAvatar": "Mostrar el avatar en los calendarios de reserva",
    "withDog": "Con perro",
    "with9Hole": "Planes para jugar 9 hoyos",
    "withPlayWithMe": "Feliz de reservar conmigo",
    "member": "Miembro",
    "memberFemale": "Miembro femenino",
    "memberMale": "Miembro masculino",
    "guest": "Invitado",
    "guestFemale": "Mujer invitada",
    "guestMale": "Hombre invitado",
    "displayOptions": "Opciones de visualización",
    "showName": "Mostrar nombre",
    "showHCP": "Mostrar hándicap",
    "showGender": "Mostrar género",
    "all": "Todo",
    "min1": "Min. 1 plaza libre",
    "min2": "Mínimo 2 plazas libres",
    "min3": "Min. 3 plazas libres",
    "min4": "Min. 4 plazas libres",
    "hcp": "Handicap",
    "syncHCP": "Sincronizar el hándicap",
    "hcpSyncOK": "Sincronización de la discapacidad con éxito",
    "hcpErrorSync": "Error al sincronizar el hándicap",
    "bookingTeetimeHelp": "Ayuda a la hora de reservar",
    "myBooking": "Mi reserva",
    "monday": "Mon",
    "tuesday": "Mar",
    "wednesday": "Miércoles",
    "thursday": "Thur",
    "friday": "Vie",
    "saturday": "Sat",
    "sunday": "Sol",
    "makeBooking": "Reservar la hora del té",
    "makeBookingAndPay": "Reservar y pagar el tiempo de espera",
    "makeProBooking": "Reservar una lección profesional",
    "makeAndPayProBooking": "Reservar y pagar una clase profesional",
    "makeRangeBooking": "Reservar espacio en la gama",
    "makeAndPayRangeBooking": "Reservar y pagar la franja horaria",
    "maxDays": "Días máximos para reservar",
    "timeSwitch": "Tiempo añadir día adicional",
    "allowAlwaysBookingHours": "Horario siempre reservable",
    "courseConfigs": "Configuración del curso",
    "unlimited": "No hay restricciones",
    "myCourseData": "Mis datos",
    "myFutureBookings": "Total de reservas",
    "myBookingToday": "Reservas fecha seleccionada",
    "myBookingWeek": "Reservas esta semana",
    "bookingCourseHelp": "Ayuda a la configuración del curso",
    "bookingCourseHelp1": "Estas configuraciones han sido depositadas por el club de golf.",
    "openFriends": "Configurar amigos",
    "editBooking": "Editar reserva",
    "@me": "Yo",
    "player": "Jugador",
    "username": "Número de tarjeta de identificación o apodo",
    "announcement": "Anuncio",
    "participants": "Participantes",
    "of": "de",
    "round": "Redondo",
    "guests": "Invitados",
    "webAvailable": "Disponible en la web",
    "PF0": "Single",
    "PF1": "Aggregat Foursome",
    "PF2": "Foursome",
    "PF3": "Foursome best drive",
    "PF4": "Chapman Foursome",
    "PF5": "Ryesome Foursome",
    "PF6": "Fourball",
    "PF7": "Fourball Bestball",
    "PF8": "Scramble",
    "PF9": "Twosome Scramble",
    "PF10": "Texas Scramble",
    "PF11": "Florida Scramble",
    "PF12": "RPR",
    "SM0": "Strokeplay",
    "SM1": "Stableford",
    "SM2": "Against Par",
    "SM3": "Matchplay",
    "SM4": "Strokeplay and Stableford",
    "SM5": "Mixed",
    "SM6": "Flag",
    "SM7": "Free",
    "SM8": "Strokeplay max score",
    "W0": "18 holes",
    "W1": "9 holes",
    "HCPREL0": "No relevante para HCPI™",
    "HCPREL1": "HCPI™-Relevante",
    "HCPREL2": "Tiger Rabbit",
    "regPeriod": "Período de inscripción",
    "regPeriodStart": "Apertura de inscripciones",
    "regPeriodEnd": "Fecha de cierre de las inscripciones",
    "hcpi": "HCPI™",
    "toStr": "a",
    "timerRunning": "Teetime sigue esperando...",
    "timerStopped": "Tu tiempo de té ahora también está disponible para otros clientes.",
    "teetimeLocked": "Teetime está bloqueado",
    "tournamentRegisterError1": "No está conectado",
    "tournamentRegisterError2": "Género no coincidente",
    "tournamentRegisterError3": "No coincide con la discapacidad",
    "tournamentRegisterError4": "Periodo de registro no coincidente",
    "tournamentRegisterError5": "La lista de inscritos está completa",
    "tournamentRegisterError6": "Grupo de edad no coincidente",
    "tournamentRegisterError7": "Registrado en #",
    "tournamentUnRegisterError1": "No está conectado",
    "tournamentUnRegisterError2": "No se puede anular el registro",
    "tournamentUnRegisterError3": "Se ha superado la fecha de anulación del registro",
    "tournamentUnRegisterError4": "No está registrado en #",
    "tournamentUnRegisterError5": "No se puede anular el registro porque se ha pagado en línea",
    "helpCoursesTime": "Fecha del curso, hora de inicio y finalización",
    "helpCoursesEnter": "Inscribirse en el curso",
    "helpCoursesUnregister": "Anular la inscripción en el curso",
    "helpCoursesSeries": "Serie de cursos",
    "helpCourses": "Cursos de ayuda",
    "helpTournamentTime": "Fecha, inicio y fin del torneo",
    "helpTournamentDown": "Abrir detalles",
    "helpTournamentUp": "Cerrar detalles",
    "helpTournamentEnter": "Registro",
    "helpTournamentEnterWaitlist": "Regístrese en la lista de espera",
    "helpTournamentUnregister": "Desregistrar",
    "helpTournamentGenderFemale": "Torneo permitido para las mujeres",
    "helpTournamentGenderMale": "Torneo permitido para los hombres",
    "helpTournamentML": "Lista de entrada",
    "helpTournamentSL": "Lista de inicio",
    "helpTournamentEL": "Lista de resultados",
    "helpTournamentAnnouncment": "Anuncio",
    "helpTournament": "Ayuda a los torneos",
    "helpEventTime": "Fecha del evento, hora de inicio y de finalización",
    "helpEventDown": "Abrir detalles",
    "helpEventUp": "Cerrar detalles",
    "helpEventEnter": "Inscríbase en el evento",
    "helpEventEnterWaitlist": "Inscríbase en la lista de espera del evento",
    "helpEventUnregister": "Anular el registro del evento",
    "helpEventGenderFemale": "Evento permitido para las mujeres",
    "helpEventGenderMale": "Evento permitido para los hombres",
    "helpEventAnnouncment": "Descripción",
    "helpEvent": "Eventos de ayuda",
    "helpRoundSL": "Lista de inicio",
    "unregister": "Firme desde",
    "unregisterSuccessful": "Se ha cerrado la sesión con éxito",
    "unregisterNotSuccessful": "Error durante el cierre de sesión",
    "registerSuccessful": "Registrado con éxito",
    "registerNotSuccessful": "Error durante el registro",
    "none": "No hay selección",
    "early": "Temprano",
    "late": "Tarde",
    "makeTournamentRegistration": "Registro",
    "makeTournamentRegistrationAndPay": "Regístrese y pague",
    "makeTournamentUnRegistration": "Desregistrar",
    "comment": "Comentario",
    "registerTournament": "Registro",
    "unregisterTournament": "Desregistrar",
    "addAdditionalPlayers": "Otros jugadores de equipo",
    "addAdditionalFlightPlayers": "Otros jugadores",
    "searchPlayer": "Buscar jugadores",
    "friends": "Amigos",
    "friendGroups": "Grupos de amigos",
    "friendsSave": "Salvar a los amigos",
    "addAdditionalFriends": "Añadir amigos",
    "friendsNotConfirmed": "Solicitud de amistad",
    "friendsDeclined": "Solicitudes de amistad rechazadas",
    "noTournaments": "No se han encontrado torneos",
    "noEvents": "No se han encontrado eventos",
    "noTeetimeCourseFound": "No se ha encontrado ningún calendario de reservas de teetime",
    "hole": "Agujero",
    "given": "dado",
    "noPros": "No se han encontrado profesionales",
    "noRanges": "No se han encontrado ranuras de alcance",
    "nextFreeSlot": "El próximo slot gratuito de hoy",
    "booked": "reservado",
    "precedingHours": "Horas de antelación para reservar una plaza",
    "precedingStornoMinutes": "Minutos hasta que se puede borrar una reserva",
    "proHelp": "Ayuda Pro",
    "proConfigs": "Configuraciones Pro",
    "rangeHelp": "Rango de ayuda",
    "rangeConfigs": "Configuración de la gama",
    "noCourses": "No se han encontrado cursos",
    "coursename": "Campo de golf",
    "placename": "Lugar",
    "priceinfo": "Información sobre el precio",
    "onlinecomments": "Comentario",
    "livescorings": "Puntuación digital",
    "noLivescorings": "No se han encontrado torneos hoy",
    "noEmail": "No hay ninguna dirección de correo electrónico disponible. Por favor, póngase en contacto con el club para añadir su dirección de correo electrónico.",
    "noEmailSMSLogIn": "No hay dirección de correo electrónico. Puedes configurar tu propio correo electrónico a través de tu número de teléfono móvil o ponerte en contacto con el club para que te guarden tu dirección de correo electrónico",
    "notActivated": "Su cuenta no está activada. Por favor, haga clic en enviar enlace de activación para verificar su correo electrónico. ¿La dirección de correo electrónico ya no está actualizada? A continuación, informe al club de golf de la dirección de correo electrónico actual y repita el proceso.",
    "sendActivationLink": "Enviar enlace de activación",
    "emailSendOK": "Correo electrónico enviado con éxito",
    "emailSendError": "Error al enviar el correo electrónico",
    "verify": "Activar la cuenta",
    "verifyEmailOK": "La cuenta se ha activado correctamente.",
    "verifyEmailError": "Error al activar la cuenta",
    "reset": "Establecer una nueva contraseña",
    "resetPassword": "Cambiar contraseña",
    "passwordConfirm": "Confirmar contraseña",
    "password8Chars": "Al menos 8 caracteres de longitud",
    "password1UpperCase": "Al menos 1 letra mayúscula",
    "password1Number": "Al menos un número",
    "password1SpecialChar": "Al menos un carácter especial (- + _ ! @ # $ % ^ & *)",
    "password1PasswordSame": "Las contraseñas deben coincidir con",
    "resetPasswordOK": "Contraseña cambiada con éxito",
    "resetPasswordError": "Error al cambiar la contraseña",
    "passwordRules": "Mente. 8 caracteres, una mayúscula, un número y un carácter especial (-, +, _, !, @, #, $, %, ^, &, *, .)",
    "bookingDeleted": "Reserva eliminada con éxito",
    "bookingAdded": "Reserva añadida con éxito",
    "dgvnumber": "Número de identificación de 10 dígitos (exclusivo para miembros de asociaciones de golf)",
    "dgvdescription": "¡Regístrese rápida y fácilmente ahora!",
    "dgvdescription1": "¿Miembro de una de las tres asociaciones de golf mencionadas?",
    "dgvdescription2": "A continuación, seleccione la tarjeta correspondiente e introduzca el número de identificación de 10 dígitos, así como los datos personales",
    "searchClubs": "Busque el país/club si no es miembro de un club alemán, suizo o austriaco",
    "searchCountry": "Buscar país/club",
    "gender": "Género",
    "male": "Hombre",
    "female": "Mujer",
    "title": "Título",
    "activationLinkSent": "Se ha enviado un enlace de activación por correo electrónico. Por favor, compruebe su bandeja de entrada y active su cuenta. También es posible que el correo electrónico haya acabado en la carpeta de spam. Su nombre de usuario para el inicio de sesión es: #",
    "cardclub": "Tarjeta",
    "marker": "Marcador",
    "clear": "Claro",
    "confirm": "Confirmar",
    "signaturePlayer": "Jugador de firma",
    "signatureMarker": "Marcador de firma",
    "read": "Leer",
    "gentleman": "Hombres",
    "ladies": "Mujeres",
    "missingScores": "Faltan las puntuaciones. No es posible confirmar la firma.",
    "hometeetimes": "Reservar teetime",
    "homefriends": "Mis amigos",
    "homecard": "Mis tarjetas RFID",
    "homepros": "Reserva Pro",
    "homecourses": "Reservar curso",
    "homeranges": "Reservar ranura de rango",
    "homecompetitions": "Calendario de torneos",
    "homelivescorings": "Tarjeta de puntuación en la aplicación",
    "homeevents": "Calendario de eventos",
    "homeMemberdata": "Mis datos",
    "homePayment": "Pago en la aplicación",
    "homeMember": "¿Ya es miembro o invitado? A continuación, utilice todos los servicios en línea directamente después de iniciar la sesión.",
    "homeEmail": "¿Dirección de correo electrónico almacenada en el club?",
    "homeNewCustomer": "Regístrese ahora como nuevo huésped y utilice todos los servicios en línea.",
    "myclub": "Mis datos",
    "personInfo": "Información personal",
    "clubInfo": "Información sobre el club",
    "addressInfo": "Información sobre la dirección",
    "bankInfo": "Información bancaria",
    "authorizePayments": "Autorizar los pagos",
    "revokePayments": "Revocar la autorización",
    "paypalPayment": "Pago con Paypal",
    "paypalAuthorized": "Ha autorizado correctamente el pago de PayPal",
    "creditCardPayment": "Pago con tarjeta de crédito",
    "cardholderName": "Nombre del titular de la tarjeta",
    "creditCardNumber": "Número de tarjeta de crédito",
    "expiration": "Caducidad",
    "accept": "Añadir tarjeta de crédito",
    "creditCardAuthorized": "Ha autorizado correctamente el pago con tarjeta de crédito",
    "creditCardAuthorizedFailed": "Autorización fallida",
    "noPaymentMethod": "Todavía no hay ningún método de pago almacenado. Simplemente guarda en el perfil PayPal o una tarjeta de crédito.",
    "paymentMethodDeleted": "El método de pago fue eliminado",
    "paymentMethodCreated": "El método de pago se ha añadido",
    "startdate": "Fecha de inicio",
    "contractstart": "Fecha de inicio del contrato",
    "contractend": "Fecha de finalización del contrato",
    "enddate": "Fecha de finalización",
    "membership": "Afiliación",
    "contract": "Contrato",
    "homeclubnum": "Número del club de origen",
    "street": "Calle",
    "city": "Ciudad",
    "telephone": "Teléfono",
    "mobile": "Móviles",
    "newsletter": "Boletín de noticias",
    "allowNewsletter": "Suscrito",
    "notAllowNewsletter": "No suscrito",
    "emailbill": "Enviar la factura por correo electrónico",
    "allowBillEmail": "Suscrito",
    "notAllowBillEmail": "No suscrito",
    "accountowner": "Propietario de la cuenta",
    "bank": "Banco",
    "iban": "IBAN",
    "bic": "BIC",
    "debOk": "Retirada de débito",
    "debitsOk": "Permitido",
    "debitsNotOk": "No se permite",
    "refNumber": "Número de referencia del mandato",
    "homeclub": "Club de la casa",
    "credit": "Crédito",
    "addFriendGroup": "Añadir grupo de amigos",
    "addCredit": "Crédito de recarga",
    "amount": "Cantidad",
    "recharge": "Recarga",
    "automaticRechargeBeyond": "Recarga automática si el crédito es inferior a este importe",
    "paymenttype": "Opción de pago",
    "rechargeBy": "Aumento por importe",
    "amountToPay": "Cantidad a pagar",
    "freeSlots": "Tragamonedas gratis",
    "addAdditionalFlightFriends": "Amigos adicionales",
    "card": "Mi tarjeta",
    "assignedCards": "Tarjetas asignadas",
    "valid": "Validez",
    "noCardFound": "No se ha encontrado ninguna tarjeta",
    "permissions": "Permisos",
    "status": "Estado",
    "locked": "Bloqueado",
    "active": "Activo",
    "cardHistory": "Historial de la tarjeta",
    "noHistory": "No hay datos disponibles",
    "clubSelection": "Selección de clubes",
    "bonuspoints": "Puntos de bonificación",
    "creditoncard": "Crédito en la tarjeta",
    "pointsoncard": "Puntos de la tarjeta",
    "getBalls": "Solicitud de obtención de bolas",
    "getPutting": "Solicitud de #",
    "getCheckIn": "Solicitar el registro",
    "getDoor": "Solicitud de",
    "pressButton": "Pulsa el botón plateado de la máquina de bolas",
    "ballmachineTimerRunning": "Pulse el botón plateado de la máquina de bolas dentro de la siguiente",
    "checkInTimerRunning": "Pulse el botón plateado en el CheckIn dentro de la siguiente",
    "doorTimerRunning": "Pulse el botón plateado de la puerta en los próximos minutos",
    "bookingTimerRunning": "Pulse el botón plateado del lector en el siguiente",
    "seconds": "Segundos",
    "appButtonError": "Error en la gestión de la solicitud. Por favor, inténtelo de nuevo en unos segundos.",
    "r0000001": "Algo salió mal al crear la transacción",
    "r0000002": "Ha creado un pago. Por favor, pulse el botón plateado del lector para confirmar el pago.",
    "r0000003": "El lector está siendo utilizado por otras personas. Por favor, espere un momento e inténtelo de nuevo",
    "r0000004": "Este número de DGV ya ha sido utilizado",
    "r0000005": "Hardware no encontrado",
    "frequency0": "",
    "frequency1": "mensualmente",
    "frequency2": "trimestral",
    "frequency3": "semestral",
    "frequency4": "anualmente",
    "frequency5": "diario",
    "packageAvailable": "disponible",
    "generalPackage": "Paquetes de canastas de bolas",
    "personalPackage": "Paquetes personales de canastas de bolas",
    "noRPRs": "No se han encontrado rondas privadas registradas hoy",
    "RPRs": "Rondas privadas registradas",
    "RPR": "Ronda privada registrada",
    "getBooking": "Solicitar el encendido",
    "news": "Noticias del club de golf",
    "imprint": "Impressum",
    "alreadyInFlight": " ya está en flight",
    "acceptAgb": "Acepto las condiciones generales",
    "acceptDSGVO": "Acepto la normativa de protección de datos",
    "agb": "Všeobecné podmínky",
    "datalegal": "Privacy policy",
    "marshalls": "Marshall",
    "send": "Enviar",
    "messageToOffice": "Mensaje para la Secretaría",
    "messageSent": "Mensaje enviado",
    "statusSet": "Se ha establecido el estado",
    "holes": "Agujeros",
    "tee": "Salida",
    "personalScorecard": "Tarjeta de puntuación personal",
    "getScorecard": "Al cuadro de mando",
    "length": "Metros",
    "faq": "FAQs",
    "yes": "Sí",
    "no": "No",
    "checkIn": "Comprobación de entrada",
    "checkInText": "¿Hay que poner el horario de hoy en el presente?",
    "DSGVO": "Política de privacidad",
    "Agb": "Condiciones generales",
    "noNews": "No hay noticias disponibles",
    "noPlayers": "No hay jugadores en el grupo",
    "functions": "Este club de golf ofrece las experiencias digitales de golf que se enumeran a continuación.",
    "wantToLogout": "Me gustaría cerrar la sesión.",
    "canLogin": "Ahora inicie sesión con la contraseña asignada y este número de 10 dígitos: ",
    "registerGuest": "Registro para invitados",
    "nickname": "Apodo",
    "contactClub": "Para modificar los datos, póngase en contacto con el club",
    "today": "Hoy",
    "hcpiInputHint": "Introduzca el HCPI™ como un número. Utilice un punto como separador para el punto decimal.",
    "noInternet": "No se han transmitido todos los resultados. Por favor, compruebe la conexión a Internet.",
    "closestClub": "El club más cercano",
    "mobileScreen1": "Experiencias digitales de golf en tu club de golf",
    "mobileScreen2": "Rangebooking",
    "mobileScreen3": "Pros & Courses",
    "mobileScreen4": "Gestionar tarjetas",
    "deleteAccount": "Eliminar la cuenta",
    "wantToDeleteAccount": "¿Borrar la cuenta?",
    "noWellnesses": "No se han encontrado ranuras de bienestar",
    "wellnesses": "Wellness",
    "wellnessHelp": "AyudaBienestar",
    "wellnessConfigs": "Configuración de bienestar",
    "makeWellnessBooking": "Reservar ranura de bienestar",
    "homewellnesses": "Reservar plaza de bienestar",
    "noTrackmans": "No se han encontrado espacios para trackman",
    "trackmans": "Trackman",
    "trackmanHelp": "Ayuda Trackman",
    "trackmanConfigs": "Configuraciones de Trackman",
    "makeTrackmanBooking": "Reserva del espacio Trackman",
    "hometrackmans": "Reserva del espacio Trackman",
    "noIndoors": "No se han encontrado espacios interiores",
    "indoor": "Indoor",
    "indoorHelp": "Ayuda indoor",
    "indoorConfigs": "Configuraciones de indoor",
    "makeIndoorBooking": "Reservar espacio indoor",
    "homeindoors": "Reservar espacio indoor",
    "countryCallingCode":"Código del país",
    "getMobileCode":"Obtener código",
    "sentMobileCode":"Su código de verificación ha sido enviado y expirará en 5 minutos.",
    "setNewEmail":"establecer un nuevo email",
    "setNewEmailDone":"El correo electrónico se ha configurado correctamente. Por favor, compruebe su bandeja de entrada de correo electrónico para activar la cuenta.",
    "mobileCondeSendOK":"El código ha sido enviado con éxito a tu móvil.",
    "mobileCondeSendError": "Se ha producido un error al enviar el código",
    "setEmailByMobileCodeOK":"El correo electrónico se ha configurado correctamente",
    "setEmailByMobileCodeError": "Se ha producido un error al configurar el correo electrónico",
    "other":"Otros",
    "alllowSearch":"Se me puede encontrar en la función de búsqueda (lista de amigos, reserva de hora de inicio, torneo)",
    "privacyInfoscreen":"mostrar Infoscreen",
    "wantToDeleteScoreCard": "Quieres borrar la tarjeta de puntuación?",
    "wantToCleanScoreCard": "Quieres borrar todas las puntuaciones?",
    "createNewRound": "Iniciar nueva ronda",
    "saved":"guardado",
    "date":"Fecha",
    "year":"Año",
    "savedScoreCard":"Marcadores guardados",
    "updateAppToUSe": "Por favor, actualice la aplicación para seguir utilizándola",
    "appUpdating": "La aplicación se está actualizando, por favor espere un momento...",
    "aboutApp": "About App",
    "updateApp": "Update",
    "mobileNoZero": "Número de móvil SIN el 0 inicial",
    "ballausgabe": "Obtener pelotas",
    "ballausgabeputting": "Seleccionar dispensador de bolas/zona de golpeo",
    "cardnumber": "Número de tarjeta",
    "checkin": "Check-in",
    "dooropen": "Puerta abierta",
    "turnon": "Encender",
    "units": "Unidades",
    "courseInfoToday": "Información del curso hoy",
    "digitalScorecard": "Tarjeta de puntuación digital de los torneos",
    "digitalLivescoring": "Torneos Livescoring",
    "saveAndGo": "Guarda y vete",
    "saveScoreError": "No se han introducido todas las puntuaciones",
    "courseStart": "Inicio",
    "courseEnd": "Última unidad",
    "refereeText": "¿Realizar llamada de árbitro?",
    "newFlight": "El flight ha cambiado. ¡Por favor, guarde la puntuación de nuevo!",
    "tutorial": "Tutoriales",
    "tutorialTeetime": "Teetimes del libro",
    "tutorialTorneos": "Gestionar torneos",
    "tutorialPros": "Reservar clases y cursos de coaching",
    "tutorialPersonal": "Mi tarjeta / Perfil / Noticias",
    "tutorialScoring": "Torneos de puntuación digital",
    "tutorialUseReader": "Funcionamiento del lector de tarjetas",
    "payLastschrift": "Pago por domiciliación bancaria",
    "payLastschriftHint": "La autorización de domiciliación se deposita en el club. El importe adeudado se cargará en la cuenta depositada",
    "addCreditLastschrift": "Recarga de crédito",
    "allow4USearch": "Quiero que me encuentren en la búsqueda de personas",
    "newsperson": "My News",
    "sunsetInfoToday": "Puesta de sol hoy",
    "sunsetInfo": "Puesta de sol",
    "sunsetBooking": "Teetime podría durar hasta la puesta de sol",
    "amigosAyuda": "AmigosAyuda",
    "friendsAccepted": "Solicitud de amistad aceptada",
    "friendsNotYetAcceppted": "Solicitud de amistad aún no aceptada",
    "friendsHelpDeclined": "Rechazada/solicitud de amistad rechazada",
    "friendsDelete": "Eliminar amigo",
    "friendsFemale": "Mujer",
    "friendsMale": "Hombre",
    "friendsAdd": "Añadir grupo de amigos o amigo",
    "friendsOpenDetail": "Detalles abiertos",
    "friendsCloseDetail": "Detalles de cierre",
    "RoundFinished": "Ronda terminada",
    "allowSingleEntryTeamError": "¡No se permite la entrada individual en el juego por equipos!",
    "clearScores": "Borrar todas las puntuaciones",
    "time": "Hora del día",
    "useLandscape": "Utilice el modo horizontal en su dispositivo móvil.",
    "general": "General",
    "bills": "Facturas",
    "teetime": "Teetime booking",
    "deleteNews": "Borrar mensaje",
    "deleteThisNews": "¿Borrar este mensaje?",
    "aboname": "Nombre",
    "aboInfo": "Suscripciones",
    "aboHistory": "Historial de suscripciones",
    "bookAndPay": "Reserva y paga",
    "noPaymentMethod": "Para recargar saldo, domiciliación bancaria en el club si es necesario",
    "rounds": "Rondas",
    "digitalScorecardLegend1": "Transmite y guarda la puntuación",
    "digitalScorecardLegend2": "No se ha introducido ni modificado la puntuación",
    "digitalScorecardLegend3": "Se ha modificado la puntuación, pero aún no se ha transmitido ni guardado",
    "digitalScorecardLegend4": "La puntuación se ha transmitido y guardado correctamente",
    "enterEmail": "Por favor, introduzca el email almacenado y solicite el email de nuevo",
    "infoScreenTitle": "Visualización Infoscreen",
    "infoScreenMessage": "Por favor, seleccione Sí para que se muestre en el Terminal Exterior (Infoscreen). Este ajuste se puede volver a cambiar en Perfil",
    "addRPR": "Registrar ronda privada",
    "syncHCPI": "Comprobar el HCPI™ actual ...",
    "noRPR": "Solicitar el HCPI™ actual no es posible. No se puede registrar ninguna ronda RPR",
    "searchMarker": "Buscar contador",
    "addRPRDebit": "Crear ronda RP y pagar con domiciliación bancaria",
    "addRPRRound": "Crear ronda RP",
    "saveRPRRound" : "La ronda RP se ha guardado y transferido correctamente",
    "saveRPRError" : "Se ha producido un error al guardar la ronda RP",
    "notAllScoresSaved" : "Firma visible cuando se han guardado todas las puntuaciones",
    "scorecardSaved" : "La puntuación se ha guardado correctamente",
    "GuestsQRCode" : "QRCodes para tarjeta de puntuación digital",
    "addLock" : "Añadir candado",
    "addLockSuccess" : "Bloqueo añadido con éxito",
    "setGolfChainHeader" : "¡Atención! Activar la pertenencia a la cadena de golf",
    "setGolfChainText" : "¿Realmente desea activar la afiliación a la cadena de golf?",
    "noBlanks" : "Sin espacios ni caracteres especiales",
    "tendigits" : "10 dígitos",
    "eightdigits" : "8 dígitos",
    "ninedigits" : "9 dígitos",
    "golfChain" : "Soy miembro de la Cadena de Golf",
    "ask9holes" : "¿Jugar 18 o 9 hoyos?",
    "ask9holesText" : "Por favor, indique cuántos hoyos piensa jugar",
    "9holes" : "9 hoyos",
    "18holes" : "18 hoyos",
    "pointPackages" : "Paquetes de puntos",
    "points" : "Puntos",
    "buypoints" : "Comprar puntos",
    "for" : "para",
    "bono" : "Bonos",
    "destinatario" : "Destinatario",
    "addRecipient" : "Introducir destinatario",
    "howtoget" : "¿Cómo desea recibir su vale?",
    "post" : "Post*",
    "addAmount" : "Introduzca el importe",
    "addEmail" : "Introduzca la dirección de correo electrónico",
    "zip" : "Código postal",
    "street2" : "Street2",
    "buyVoucher" : "Comprar vale",
    "addVoucherSuccessful" : "Vale comprado con éxito",
    "addVoucherNotSuccessful": "Se ha producido un error al comprar el vale",
    "directPayment": "Pago",
    "homevoucher":"Comprar vales",
    "pca":"PCA",
    "PCAVoucher": "PCA vales",

    "de": "Alemán",
    "en": "Inglés",
    "it": "Italiano",
    "fr": "Francés",
    "cz": "Checa",
    "cn": "Chino",
    "nl": "Holandés",
    "es": "Española",

    "ls0001": "Sus datos bancarios no están completos, póngase en contacto con su club de golf",
    "ls0002": "El club de golf ha desactivado el pago por domiciliación bancaria",
    "dfdae65b": "No se ha encontrado ningún usuario con la dirección de correo electrónico indicada",
    "71b72952": "Contraseña incorrecta",
    "71b72951": "Falta la dirección de correo electrónico. Por favor, póngase en contacto con el club.",
    "71b72953": "Error de ficha",
    "71b72954": "Ficha caducada",
    "71b72955": "Contraseña inválida",
    "df6b0128": "Un jugador del grupo no puede reservar",
    "df6b0129": "No está conectado",
    "df6b0130": "Usuario no encontrado",
    "df6b0131": "Teetime está bloqueado",
    "df6b0132": "Teetime no encontrado",
    "df6b0133": "Reservar en el pasado",
    "df6b0134": "La reserva no está configurada",
    "df6b0135": "La reserva no se puede borrar",
    "df6b0136": "No hay plazas disponibles",
    "df6b0137": "Curso no encontrado",
    "df6b0138": "No se permite el registro",
    "df6b0139": "No se puede anular el registro",
    "df6b0140": "Calendario no encontrado",
    "df6b0141": "Hora de finalización alcanzada",
    "df6b0142": "Se ha alcanzado el tiempo de reserva anterior",
    "df6b0143": "Ranura no disponible",
    "df6b0144": "El tiempo está en el pasado",
    "df6b0145": "Días máximos alcanzados",
    "df6b0146": "Periodo de supresión alcanzado",
    "df6b0147": "Torneo no encontrado",
    "df6b0148": "Pro no encontrado",
    "df6b0149": "Rango no encontrado",
    "df6b0150": "Teetime pagado en línea",
    "df6b0151": "Gama pagada en línea",
    "df6b0152": "Pro pagado en línea",
    "df6b0153": "Carrito no disponible",
    "df6b0154": "Usuario bloqueado para 4U",
    "19e28470": "El usuario ya existe",
    "7e341661": "Error interno",
    "7e341662": "Error de inicio de sesión",
    "bfe57d09": "No se ha encontrado ningún usuario con este nombre de usuario",
    "bfe57d10": "No hay correo electrónico para el usuario",
    "b4d05e56": "Algo salió mal al subir el archivo",
    "b4d05e57": "Algo salió mal al borrar el archivo",
    "b4d05e58": "Algo ha ido mal al guardar los datos",
    "b4d05e59": "Algo salió mal al obtener los datos",
    "b4d05e60": "El número de tarjeta de identificación ya está registrado. Por favor, utilice este número para iniciar sesión",
    "b4d05e61": "No se ha encontrado el país/club seleccionado",
    "b4d05e62": "Una persona con los datos proporcionados ya existe en el sistema. Inicie sesión con este ID de usuario: #",
    "b4d05e63": "No se ha encontrado a la persona. Por favor, compruebe los datos dados con los onces en la tarjeta de identificación (nombre, apellido)"
  }
}