<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>PCA</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-card>
        <ion-card-content>
          <ion-card-header  class="ion-no-padding">
            <strong style="font-size: large;">{{ $t('PCAVoucherAdd') }}</strong>
          </ion-card-header>
          <ion-card v-if="hasPicture == false">
            <ion-card-content>
              <ion-card-header  class="ion-no-padding">
                <strong style="font-size: medium;color: red;">{{ $t('PCAVoucherAddNoPicture') }}</strong>
              </ion-card-header>
            </ion-card-content>
          </ion-card>
          <ion-card v-else class="ion-no-padding ion-no-margin">
            <ion-card-content v-if="selectedItem">
              <ion-list>
                <ion-list>
                  <ion-item>
                    <ion-label class="ion-text-wrap" slot="start">{{ $t('membership')}}</ion-label>
                    <ion-label class="ion-text-wrap" slot="end">{{ selectedItem.memberstatus == '' ? 'Gast' : selectedItem.memberstatus }} {{ selectedItem.isWeekday ? '(wochentags)' : '' }}</ion-label>
                  </ion-item>
                  <ion-item>
                    <ion-label class="ion-text-wrap">{{ $t('PCAAllowed') }}</ion-label>
                    <ion-label slot="end" :color="selectedItem.isForPCA == false ? 'danger' : 'success'">{{ selectedItem.isForPCA == false ? 'Nein' : 'Ja' }}</ion-label>
                  </ion-item>
                  <ion-item>
                    <ion-label slot="start" class="ion-text-wrap">{{ $t('PCANumVouchers') }}</ion-label>
                    <ion-label class="ion-text-wrap" slot="end">{{ selectedItem.numvoucherssold }} {{ selectedItem.numvouchersoverall > 0 ? ' von ' + selectedItem.numvouchersoverall + ' möglichen' :  '' }} {{ '(' + selectedItem.numvouchersfree + ' frei)' }}</ion-label>
                  </ion-item>
                  <ion-item>
                    <ion-label class="ion-text-wrap" slot="start">{{ $t('PCANumVouchersUsed') }}</ion-label>
                    <ion-label  class="ion-text-wrap" slot="end">{{ selectedItem.numvouchersused }}</ion-label>
                  </ion-item>
                </ion-list>
              </ion-list>
              <ion-card v-if="selectedItem.isForPCA == true" class="ion-no-padding ion-no-margin">
                <ion-card-header>{{ $t('PCAVoucherLong') }}</ion-card-header>
                <ion-card-content>
                  <ion-list>
                    <ion-item v-for="(partnerClub, i) in selectedItem.partnerClubs" :key="i">
                      <ion-grid class="ion-no-padding">
                        <ion-row>
                          <ion-col size="auto">
                            {{ partnerClub.name + ' (' + partnerClub.customerNumVouchers + (partnerClub.numvouchersmax > 0 ? ' von ' + partnerClub.numvouchersmax + ')' : ')') }}
                            <ion-button v-if="partnerClub.playingConditions && partnerClub.playingConditions != ''" fill="clear" style="margin-top: -5px;" @click="showPlayingConditions(partnerClub)" >
                              <ion-icon  style="zoom: 1.4;" color="danger" :ios="informationCircleOutline" :md="informationCircleOutline"></ion-icon>
                            </ion-button>
                          </ion-col>
                        </ion-row>
                        <ion-row>
                          <ion-col>
                            <ion-item>
                              <ion-grid slot="start">
                                <ion-row>
                                  <ion-col size="auto">
                                    <ion-button class="ion-no-padding" fill="clear" @click="setNumVouchers(selectedItem.partnerClubs.find(f => f.id == partnerClub.id), false)">
                                      <ion-icon  style="zoom: 1.4;" color="danger" :ios="removeCircleOutline" :md="removeCircleOutline"></ion-icon>
                                    </ion-button>
                                  </ion-col>
                                  <ion-col size="auto">
                                    <ion-label style="margin-top: 7px;font-weight: bold;">{{ selectedItem.partnerClubs.find(f => f.id == partnerClub.id).numVouchers }}</ion-label>
                                  </ion-col>
                                  <ion-col size="auto">
                                    <ion-button class="ion-no-padding" fill="clear" @click="setNumVouchers(selectedItem.partnerClubs.find(f => f.id == partnerClub.id), true)">
                                      <ion-icon  style="zoom: 1.4;" color="success" :ios="addCircleOutline" :md="addCircleOutline"></ion-icon>
                                    </ion-button>
                                  </ion-col>
                                </ion-row>
                              </ion-grid>

                              <ion-label class="ion-text-wrap" slot="end">{{ selectedItem.numvouchersfree >= 0 && (parseInt(selectedItem.numvoucherssold) + parseInt(sumVouchers)) > selectedItem.numvouchersfree ? partnerClub.priceToPay : '0.00' }} EUR</ion-label>
                            </ion-item>
                          </ion-col>
                        </ion-row>
                      </ion-grid>


                    </ion-item>
                    <ion-item style="font-weight: bold;">
                      <ion-label>{{ $t('PCASumVoucher') }}</ion-label>
                      <ion-label slot="end">{{ parseInt(sumVouchers) }}</ion-label>
                    </ion-item>
                    <ion-item  v-if="selectedItem.partnerClubs.filter(f => f.priceToPay > 0).length > 0" style="font-weight: bold;">
                      <ion-label>{{ $t('PCASumVoucherAmount') }}</ion-label>
                      <ion-label slot="end">{{ selectedItem.partnerClubs.reduce((acc, item) => acc + parseFloat(item.priceToPay), 0).toFixed(2) }} EUR</ion-label>
                    </ion-item>

                    <ion-item v-if="selectedItem.partnerClubs.filter(f => f.priceToPay > 0).length > 0">
                      <ion-label>{{ $t('credit')}}</ion-label>
                      <ion-label :color="selectedItem.partnerClubs.reduce((acc, item) => acc + parseFloat(item.priceToPay), 0) > selectedItem.creditAmount ? 'danger': ''" slot="end">{{ selectedItem.creditAmount.toFixed(2) }} EUR</ion-label>
                    </ion-item>

                    <ion-item>
                      <ion-button v-if="selectedItem.partnerClubs.filter(f => f.priceToPay > 0).length == 0" @click="createVouchers(false)" :disabled="sumVouchers <= 0" type="submit" color="success">{{ $t('PCACreateVoucher') }}</ion-button>
                      <ion-button v-else @click="createVouchers(true)" :disabled="sumVouchers <= 0 || (selectedItem.partnerClubs.reduce((acc, item) => acc + parseFloat(item.priceToPay), 0) > selectedItem.creditAmount)" type="submit" color="success">{{ $t('PCAPayCreateVoucher') }}</ion-button>
                    </ion-item>
                  </ion-list>
                </ion-card-content>
              </ion-card>
            </ion-card-content>
          </ion-card>
         
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-content>
          <ion-card-header  class="ion-no-padding">
            <strong style="font-size: large;">{{ $t('PCAVoucher') }}</strong>
          </ion-card-header>
          <ion-card class="ion-no-padding ion-no-margin" v-for="(p, i) in pcaData" :key="i">
            <ion-card-content>
              <ion-card-header  class="ion-no-padding">
                <strong style="font-size: x-large;">{{ p.vouchernum}}</strong> {{ p.pcaname }}
              </ion-card-header>
              <div><img :class="imgClass" :src="p.picture" @click="changeimgClass()"></div>
            </ion-card-content>
          </ion-card>
         
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, modalController, alertController, toastController, IonCard, 
        IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol, loadingController, IonCardContent, IonInput } from '@ionic/vue';
import { defineComponent, ref, computed, watch } from 'vue';
import { personOutline, calendarClearSharp, newspaperOutline, informationCircleOutline, removeCircleOutline, addCircleOutline } from 'ionicons/icons';
import { useQuery, useMutation, useResult } from '@vue/apollo-composable'
import Accordion from "./template/Accordion";
import { getAppPages } from '@/composition'
import store from '../main'
import getPCAQueryFile from '../graphql/getPCA.query.gql'
import findCustomerClubDBQueryFile from '../graphql/findCustomerClubDB.query.gql' 
import createVouchersMutationFile from '../graphql/createVouchers.mutation.gql'
import translate from '@/plugins/translate';

export default defineComponent({
  name: 'PCA',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar, IonCardContent,
    IonButton, IonLabel, IonCard, IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol, Accordion, IonInput
  },
  setup() {
    let selectedItem = ref()
    const voucherImages = ref([])

    const isLoggedIn = computed(() => store.getters.isLoggedIn ) 
    const imgClass = ref('imgSmall')

    const { mutate: createVouchersMutation } = useMutation(createVouchersMutationFile, { errorPolicy: 'all'});

    const {refetch: getPCARefetch, result: getPCAResult } = useQuery(getPCAQueryFile, () => ({ }), () => ({ fetchPolicy: 'no-cache', enabled: true }) )
    const pcaData = useResult(getPCAResult, null, data => data.getPCA.vouchers)
    const hasPicture = useResult(getPCAResult, null, data => data.getPCA.hasPicture)

    const { refetch: findCustomerClubDBRefetch, result: findCustomerClubDBResult } = useQuery(findCustomerClubDBQueryFile, () => ({ }), () => ({ fetchPolicy: 'no-cache', enabled: isLoggedIn.value == true } ))
    const customer = computed(() => findCustomerClubDBResult.value?.findCustomerClubDB.customer ?? [])
    const sumVouchers = computed(() => selectedItem.value?.partnerClubs?.reduce((n, {numVouchers}) => n + parseInt(numVouchers), 0) ?? 0)

    watch(customer, (newValue) => {
      selectedItem.value = customer.value
    })

    const setNumVouchers = (partnerClub, isPlus) => {
      const numVouchers = parseInt(partnerClub.numVouchers + (isPlus ? 1 : -1))
      if (numVouchers > getMaxValue(partnerClub))
        return
      if (numVouchers < 0)
        return

      partnerClub.numVouchers = parseInt(partnerClub.numVouchers + (isPlus ? 1 : -1))

      const numOverFree = selectedItem.value.partnerClubs.reduce((acc, item) => acc + parseInt(item.numVouchers), 0) + parseInt(selectedItem.value.numvoucherssold) - selectedItem.value.numvouchersfree
      const numToPay = numOverFree > 0 && selectedItem.value.partnerClubs.filter(f => f.id != partnerClub.id).reduce((acc, item) => acc + parseInt(item.itemsPayed), 0) > 0 ? parseInt(partnerClub.numVouchers) : (numOverFree > 0 ? selectedItem.value.partnerClubs.reduce((acc, item) => acc + parseInt(item.itemsPayed), 0) + (isPlus ? 1 : -1) : 0)
      partnerClub.priceToPay = parseFloat((numToPay <= 0 ? 0 : numToPay) * partnerClub.articlePrice).toFixed(2)
      partnerClub.itemsPayed = parseFloat((numToPay <= 0 ? 0 : numToPay))
    }

    const showPlayingConditions = (club) => {
      const alert =  alertController
        .create({
          header: translate('Spielbedingungen') + ' ' + club.name,
          message: club.playingConditions,
          buttons: [
            {
              text: translate('ok') ,
              handler: async () => {
              },
            },
          ],
        }).then(res => {
          res.present()
        })
    }

    const getMaxValue = (partnerClub) => {
      const sum = selectedItem.value.partnerClubs.reduce((acc, item) => acc + parseInt(item.numVouchers), 0)
      if ((sum + parseInt(selectedItem.value.numvoucherssold)) >= selectedItem.value.numvouchersoverall)
        return parseInt(partnerClub.numVouchers)
      else if (parseInt(sumVouchers.value) == 4)
        return parseInt(partnerClub.numVouchers)
      else if (partnerClub.numvouchersmax > 0 && (partnerClub.customerNumVouchers + parseInt(partnerClub.numVouchers)) >= partnerClub.numvouchersmax)
        return parseInt(partnerClub.numVouchers) - partnerClub.customerNumVouchers
      else
        return 4
    }

    const validateNumVouchers = async (partnerClub, i, event) => {
      partnerClub.numVouchers = parseInt(event.target.value)

      const numOverFree = selectedItem.value.partnerClubs.reduce((acc, item) => acc + parseInt(item.numVouchers), 0) + parseInt(selectedItem.value.numvoucherssold) - selectedItem.value.numvouchersfree
      const numToPay = numOverFree > 0 && selectedItem.value.partnerClubs.filter(f => f.id != partnerClub.id).reduce((acc, item) => acc + parseInt(item.itemsPayed), 0) > 0 ? parseInt(partnerClub.numVouchers) : (numOverFree > 0 ? numOverFree - selectedItem.value.partnerClubs.reduce((acc, item) => acc + parseInt(item.itemsPayed), 0) : 0)
      partnerClub.priceToPay = parseFloat((numToPay <= 0 ? 0 : numToPay) * partnerClub.articlePrice).toFixed(2)
      partnerClub.itemsPayed = parseFloat((numToPay <= 0 ? 0 : numToPay))
    }

    const createVouchers = (createBill) => {
      if (createBill) {
        for (const club of selectedItem.value.partnerClubs.filter(f => f.numVouchers > 0)) {
          club.paymentID = selectedItem.value.creditPaymentID
        }
      }
      createVouchersMutation({ vouchers: JSON.stringify(selectedItem.value.partnerClubs.filter(f => f.numVouchers > 0)), custno: selectedItem.value.custno, email: selectedItem.value.email })
      .then(async res => {
        if (res && res.data.createVouchers && res.data.createVouchers.success == true) {
          for (const club of selectedItem.value.partnerClubs) {
            selectedItem.value.numvoucherssold += parseInt(club.numVouchers)
            selectedItem.value.creditAmount -= parseFloat(club.priceToPay)
            club.numVouchers = 0
            club.priceToPay = '0'
            club.itemsPayed = 0
            club.prevVal = 0
            voucherImages.value = res.data.createVouchers.base64Imgs
            getPCARefetch()
          }
          toastController.create({ message: translate('addVoucherSuccessful'), duration: 5000, color: 'success' }).then(res => res.present())
        }
        else {
          toastController.create({ message: translate('addVoucherNotSuccessful'), duration: 5000, color: 'danger' }).then(res => res.present())
          findCustomerClubDBRefetch()
        }
      })
      .catch(err => {
        findCustomerClubDBRefetch()
      })   
    }
  

    return {
      pcaData, imgClass, hasPicture, getMaxValue, validateNumVouchers, sumVouchers, selectedItem, createVouchersMutation, createVouchers, informationCircleOutline, showPlayingConditions, removeCircleOutline, addCircleOutline, setNumVouchers
    }
  },
  computed: {
      UserData : function(){ return this.$store.getters.UserData},
  },
  methods: {
    changeimgClass() {
      this.imgClass = this.imgClass == 'imgBig' ? 'imgSmall' : 'imgBig'
    },  
  }
});
</script>

<style scoped>

.imgSmall {
  width: 250px;
}

.imgBig {
  width: 100%;
}

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>