<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Tutorials</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-card>
        <ion-card-content>
          <li v-if="appPages.appPages && appPages.appPages.findIndex(page => page.title.toLowerCase() === 'teetimes') >= 0">
            <ion-button fill="clear" @click="openInNewTab('https://www.youtube.com/watch?v=TZkTTMw0MtQ')" color="primary">{{ $t('tutorialTeetime')}}</ion-button>
          </li>
          <li v-if="appPages.appPages && appPages.appPages.findIndex(page => page.title.toLowerCase() === 'competitions') >= 0">
            <ion-button fill="clear" @click="openInNewTab('https://www.youtube.com/watch?v=W5da_IqtJkk&feature=youtu.be')" color="primary">{{ $t('tutorialTournaments')}}</ion-button>
          </li>
          <li v-if="appPages.appPages && (appPages.appPages.findIndex(page => page.title.toLowerCase() === 'pros') >= 0 || appPages.appPages.findIndex(page => page.title.toLowerCase() === 'courses') >= 0)">            
            <ion-button fill="clear" @click="openInNewTab('https://www.youtube.com/watch?v=bj5Pfwz-UVE&feature=youtu.be')" color="primary">{{ $t('tutorialPros')}}</ion-button>
          </li>
          <li v-if="appPages.appPages && appPages.appPages.findIndex(page => page.title.toLowerCase() === 'card') >= 0">
            <ion-button fill="clear" @click="openInNewTab('https://www.youtube.com/watch?v=IWofUUH1cEU&feature=youtu.be')" color="primary">{{ $t('tutorialPersonal')}}</ion-button>
          </li>
          <li v-if="appPages.appPages && appPages.appPages.findIndex(page => page.title.toLowerCase() === 'livescorings') >= 0">
            <ion-button fill="clear" @click="openInNewTab('https://www.youtube.com/watch?v=XH7x-iT5EwE')" color="primary">{{ $t('tutorialScoring')}}</ion-button>
          </li>
          <li v-if="appPages.appPages && appPages.appPages.findIndex(page => page.title.toLowerCase() === 'card') >= 0">
            <ion-button fill="clear" @click="openInNewTab('https://www.youtube.com/watch?v=eq3lxWDsv94&feature=youtu.be')" color="primary">{{ $t('tutorialUseReader')}}</ion-button>
          </li>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, modalController, toastController, IonCard, 
        IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol, loadingController, IonCardContent } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { personOutline, calendarClearSharp, newspaperOutline } from 'ionicons/icons';
import { useQuery, useResult } from '@vue/apollo-composable'
import Accordion from "./template/Accordion";
import { getAppPages } from '@/composition'

export default defineComponent({
  name: 'Tutorial',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar, IonCardContent,
    IonButton, IonLabel, IonCard, IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol, Accordion
  },
  setup() {
    const appPages = ref(getAppPages())
    //console.log("🚀 ~ file: Tutorial.vue:58 ~ setup ~ appPages", appPages.value.findIndex(page => page.title.toLowerCase() === 'test'))
    return {
      appPages
    }
  },
  methods: {
    openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer');
    },  
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>